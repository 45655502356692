import WoonkamerLinks from '../images/Woonkamer links.jpg';
import Hal_Placeholder from '../images/Hal Placeholder.jpg';
import WoonkamerRechts from '../images/Woonkamer rechts.jpg';
import WoonkamerRechts2 from '../images/Woonkamer rechts 2.jpg';
import Keuken1 from '../images/Keuken1.jpg';
import Keuken2 from '../images/Keuken2.jpg';
import Keuken3 from '../images/Keuken3.jpg';
import Balkon1 from '../images/Balkon1.jpg';
import Balkon2 from '../images/Balkon2.jpg';
import MapsFront from '../images/mapsFront.png';

export const SliderData = [{
        title: 'Patronaat 36',
        price: '€225.000',
        path: '/homes',
        label: '',
        image: MapsFront,
        alt: 'House'
    },
    {
        title: 'Hal',
        price: '',
        path: '/homes',
        label: '',
        image: Hal_Placeholder,
        alt: 'House'
    },
    {
        title: 'Woonkamer',
        price: '',
        path: '/homes',
        label: '',
        image: WoonkamerLinks,
        alt: 'House'
    },
    {
        title: 'Woonkamer',
        price: '',
        path: '/homes',
        label: '',
        image: WoonkamerRechts,
        alt: 'House'
    },
    {
        title: 'Woonkamer',
        price: '',
        path: '/homes',
        label: '',
        image: WoonkamerRechts2,
        alt: 'House'
    },
    {
        title: 'Keuken',
        price: '',
        path: '/homes',
        label: '',
        image: Keuken1,
        alt: 'House'
    },
    {
        title: 'Keuken',
        price: '',
        path: '/homes',
        label: '',
        image: Keuken2,
        alt: 'House'
    },
    {
        title: 'Keuken',
        price: '',
        path: '/homes',
        label: '',
        image: Keuken3,
        alt: 'House'
    },
    {
        title: 'Balkon',
        price: '',
        path: '/homes',
        label: '',
        image: Balkon1,
        alt: 'House'
    },
    {
        title: 'Balkon',
        price: '',
        path: '/homes',
        label: '',
        image: Balkon2,
        alt: 'House'
    }
];