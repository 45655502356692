import UitzichtOmgeving from '../images/Uitzicht.jpg';
import Placeholder from '../images/placeholder.svg';

export const InfoData = [{
    heading: "Berging",
    paragraphs: [{text : 'Het appartement heeft een berging waar eenvoudig de fiets kan worden gestald, of welke kan worden gebruikt als opslag.'}],
    buttonLabel: 'View Homes',
    image: Placeholder,
    reverse: false,
    delay: 100
},
{
    heading: "Bijzonderheden",
    paragraphs: [{text :'De kosten van de VVE bedragen 170,07 euro (2025) per maand.'}, 
        {text: 'Er is een lift en intercom aanwezig.'}, 
        {text: 'Er is een gedeeld dakteras aanwezig.'}],
    buttonLabel: 'View Homes',
    image: Placeholder,
    reverse: false,
    delay: 100
},
{
    heading: "Energielabel",
    paragraphs: [{text :'Het appartement heeft het energielabel C' +
    ' Het is voorzien van nieuwe HR+ ramen en het dak is onlangs vernieuwd. Hierdoor blijft het appartement in de zomer relatief koel en in de winter relatief warm'+
    ' Ook is er een airco aanwezig, Deze airco kan zowel koelen en verwarmen met een goed rendament. Er is ook een ketel aanwezig die kan worden ingezet als verwarming,' +
    ' Deze ketel dient ook als warm water voorziening.'}],
    buttonLabel: 'View Homes',
    image: Placeholder,
    reverse: false,
    delay: 100
},
{
    heading: "Omgeving",
    paragraphs: [{text :'Patronaat 36 ligt in het centrum van Horst,' +
    ' Veel voorzieningen zijn op loop of fietsafstand. Waaronder meerdere supermarkten,' +
    ' scholen, winkels, horecagelegenheden en (sport)verenigingen/accommodaties.'}, {text :'Weergeven op kaart https://maps.app.goo.gl/XKZ9YK9PwyWzoA9h6'}],
    buttonLabel: 'View Homes',
    image: UitzichtOmgeving,
    reverse: false,
    delay: 100
}]

// export const InfoDataTwo = {
//     heading: 'Modern Designs',
//     paragraphOne: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
//     paragraphTwo: 'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris ' +
//     'nisi ut aliquip.',
//     buttonLabel: 'View Homes',
//     image: ImageOneInterior,
//     reverse: true,
//     delay: 300
// }
